:root {
    --warning: rgb(219, 149, 36);
    --green-color: rgb(32, 220, 32);
    --red-color: rgb(255, 18, 1);
}

.MuiSnackbar-root {
    top: 50px!important;
    bottom: auto!important;
    position: fixed;
}