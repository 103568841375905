.transaction-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 20px;
}

.transaction-time {
    opacity: 0.8;
    font-size: 12px;
}

.transaction-body-avatar {
    display: flex;
    background-color: var(--attach_picker_tab_inactive_background);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.transaction-body > h1{
    margin: 10px;
}

.transaction-body-message {
    background-color: var(--attach_picker_tab_inactive_background);
    width: calc(100% - 40px);
    border-radius: 8px;
    margin: 10px;
    padding: 10px;
    text-align: left;
}

.transaction-body-message > h1 {
    font-size: 14px;
    opacity: 0.6;
    text-transform: uppercase;
    margin: 0;
}

.transaction-body-message-tag {
    font-size: 14px;
    opacity: 0.6;
    text-transform: uppercase;
    margin: 0;
}

.transaction-body-message > p {
    margin: 10px 0;
    flex-wrap: wrap;
    word-wrap: break-word;
}

.links-qr-code {
    width: 200px;
    height: 200px;
    margin: 0 auto;
}

.links-qr-code-body {
    margin: 10px;
    padding: 10px;
    border-radius: 12px;
}

.transaction-body > p {
    margin: 10px;
}

.links-qr-code-body-buttons {
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.links-qr-code-body-buttons > button {
    margin: 5px;
}

.links-qr-code-body > p {
    overflow: ellipsis;
    flex-wrap: wrap;
    word-wrap: break-word;

    -ms-word-break: break-all;
    word-break: break-all;

    /* Non standard for webkit */
     word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;

    cursor: pointer;
    border-radius: 8px;
}

.pay-modal-body {
    padding: 10px;
    padding-bottom: 20px;
}

.pay-modal-body-in {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pay-modal-body-in > p {
    opacity: 0.6;
}

.pay-input {
    background: rgba(0, 0, 0, 0);
    text-align: center;
    color: var(--header_text);
    -webkit-appearance: none;
    margin: 0;
    font-size: 30px;
    border: none;
    outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.pay-modal-body-amount {
    background-color: var(--attach_picker_tab_inactive_background);
    padding: 10px;
    border-radius: 8px;
}

.pay-modal-body-amount > p {
    margin: 0;
    opacity: 0.8;
    text-transform: uppercase;
}

.pay-modal-body-amount > h1 {
    margin: 5px;
    background-image: radial-gradient( circle farthest-corner at 40% 20%,  #7833ac 0.1%, #743aa4 99.8% );
    -webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.transaction-history-item-main {
    border-radius: 8px;
    margin: 10px;
    padding: 5px;

    background-color: var(--attach_picker_tab_inactive_background);
}