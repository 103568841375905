
.first-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    margin-top: 50px;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
}

.mnemonic-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    width: 100%;
}

.mnemonic-items > div {
    background-color: var(--attach_picker_tab_inactive_background);
    display: grid;
    grid-template-columns: 20px 1fr;
    padding: 5px;
    margin: 3px;
    border-radius: 8px;
    width: calc(100% - 20px);
}

.mneminic-autocomplit {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: antiquewhite;
    padding: 10px;
}

.mneminic-autocomplit > div > p {
    color: var(--header_text);
}