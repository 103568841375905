.sed-nft-item {
    display: grid;
    grid-template-columns: 110px 1fr;
    margin-bottom: 10px;
    max-height: 100px;
}

.sed-nft-item > div {
    max-height: 100px;
}

.sed-nft-item > img {
    width: 100px;
    border-radius: 8px;
}

.sed-nft-item > div > h1 {
    font-size: 16px;
    height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 3px;
}

.sed-nft-item > div > p {
    font-size: 14px;
    margin: 3px;
    max-height: calc(100% - 28px);
    overflow: hidden;
	text-overflow: ellipsis;
    display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
}

.nft-history-item {
    display: grid;
    grid-template-columns: 50px 1fr;
    margin: 0 auto;
    width: max-content;
    background-color: var(--attach_picker_tab_active_icon);
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.nft-history-item > img {
    width: 50px;
    border-radius: 5px;
}

.nft-history-item > div > h1 {
    font-size: 14px;
    margin: 5px;
}

.nft-history-item > div > p {
    font-size: 14px;
    margin: 5px;
}

.nft-history-item-main > p {
    text-align: center;
    opacity: 0.7;
    text-transform: uppercase;
}