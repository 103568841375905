.card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 180px;
    background-color: var(--attach_picker_tab_inactive_background);
    background: url(/src/img/fon.jpeg) !important;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: 100%!important;
    margin: 10px;
    margin-bottom: 5px;
    border-radius: 8px;
    color: aliceblue;
}

.card-qr {
    position: absolute;
    top: 5px;
    right: 5px;
}

.card > p {
    overflow: hidden;
	text-overflow: ellipsis;
    white-space: nowrap;

    font-weight: 800;
    padding: 15px;
    font-weight: 24px;
    position: absolute;
    width: calc(100% - 30px);
    bottom: 10px;
    margin: 0;
    color: aliceblue;
    background-color: rgba(31, 31, 31, 0.661);
}

.card > h1 {
    font-size: 28px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
}

.main-pay-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 5px;
}

.pay-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: radial-gradient( circle farthest-corner at 40% 20%,  #450e70 0.1%, #0f001b 99.8% );
    margin: 5px;
    min-height: 70px;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
    color: whitesmoke;
}

.pay-button > p {
    font-weight: 700;
    text-transform: uppercase;
    margin: 5px;
}


.pay-button:active {
    transition: 0.2s;
    opacity: 0.7;
}

.transactions-null {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.7;
    text-align: center;
    margin-top: 30px;
}

.card-full {
    display: flex;
    position: absolute;
    left: 5px;
    top: 5px;
    font-size: 10px;
    opacity: 0.7;
}

.transaction-history-item {
    position: relative;
    background-color:  var(--attach_picker_tab_inactive_background);
    margin: 10px;
    padding: 5px;
    border-radius: 8px;
    cursor: pointer;
    min-height: 40px;
}

.transaction-history-item:active {
    transition: 0.2s;
    opacity: 0.7;
}

.transaction-history-item-date {
    font-size: 10px;
    position: absolute;
    right: 5px;
    bottom: 5px;
}

.transaction-history-item > div > p {
    margin: 5px;
}

.transaction-history-item-wallet {
    overflow: hidden;
	text-overflow: ellipsis;
    white-space: nowrap;
    margin: 3px;
    margin-left: 5px;
    font-size: 12px;
    opacity: 0.7;
    width: 50%;
}

.transaction-history-item > p {
    position: absolute;
    right: 5px;
    top: 5px;
    margin: 0;
}

.history-body > h1 {
    font-size: 14px;
    text-transform: uppercase;
    margin-left: 10px;
}

.pay-modal-body > p{
    opacity: 0.6;
}

.jettons-body {
    position: relative;
    background-color:  var(--attach_picker_tab_inactive_background);
    margin: 10px;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    min-height: 40px;
    display: grid;
    grid-template-columns: 40px 1fr 20px;
    align-items: center;
}

.jettons-body:active {
    opacity: 0.6;
}

.jettons-body > img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
}

.jettons-body > p {
    position: absolute;
    font-size: 16px;
    right: 10px;
}

.jettons-body > label {
    padding: 0;
    position: absolute;
    font-size: 16px;
    right: 10px;
}

.jettons-body > div > h1 {
    margin: 0;
    margin-left: 5px;
    font-size: 14px;
}

.jettons-body > div > p {
    font-size: 12px;
    margin: 5px;
    opacity: 0.7;
}

.nft-collection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
}


.nft-mini-item {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 3px;
    align-items: center;
    justify-content: center;
    width: calc(100% - 10px);
}

.nft-mini-item > img:active {
    opacity: 0.6;
}
.nft-mini-item > img {
    width: 100%;
    border-radius: 6px 6px 0 0;
    background-color:  var(--attach_picker_tab_inactive_background);
    background-image: url(/src/img/img.jpeg);
    background-position: center;
    background-size: auto 100%;
}

.nft-mini-item > p {
    overflow: hidden;
	text-overflow: ellipsis;
    white-space: nowrap;

    width: calc(100% - 5px);
    bottom: 0px;
    margin: 0;
    left: auto;
    text-align: center;
    font-size: 12px;
    background-color:  var(--attach_picker_tab_inactive_background);
    border-radius: 0 0 5px 5px;
    padding: 3px;
}

.stack-images {
    position: absolute;
    right: 5px;
    bottom: 5px;
}

.card-online {
    position: absolute;
    top: 10px;
    font-size: 10px;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
}

.card-online > p {
    margin: 0;
    margin-left: 5px;
}

.card-online > div {
    background-color: var(--green-color);
    width: 10px;
    height: 10px;
    border-radius: 50%;
}